import React from "react"
import { Layout } from "../components/Layout"
import { SEO } from "../components/SEO"
import containerClasses from "../styles/container.module.css"

const TermsPage = () => (
  <Layout>
    <SEO
      title="Complaints - Miss Elizabeth Hawkes"
      description="Miss Elizabeth Hawkes – Complaints procedure and guidance for patients."
    />
    <div className={`${containerClasses.container} py-30`}>
      <div className="col-span-4 md:col-span-8 lg:col-start-2 lg:col-span-10">
        <h1 className="fluid-heading-04">Complaints Guidance</h1>
        <p>
          Dr. Hawkes sees a large number of patients each year and is constantly
          committed to improving the experience and care for all of her
          patients. An important part of this is to gather feedback from her
          patients, both positive and negative. We therefore welcome all forms
          of feedback and value any concerns or complaints which you may have.
        </p>
        <p className="pt-4">
          If you are unhappy or dissatisfied in any way about your experience,
          we welcome your comments or feedback, and promise to address them
          promptly, honestly and thoroughly. Please email{" "}
          <a href="mailto:admin@drelizabethhawkes.com">
            admin@drelizabethhawkes.com
          </a>{" "}
          with as much detail as you can. The best way for us to resolve any
          issue is for you to report it as close to the time of occurrence as
          you can. It is important to note that letting us know your feedback or
          comments will not affect your care in any way.
        </p>
        <p className="pt-4">
          You can raise your concerns verbally with our secretary or Dr. Hawkes,
          and they will do the best to resolve the matter as soon as possible.
        </p>
        <p className="pt-4">
          If you wish to make a more serious complaint, please email{" "}
          <a href="mailto:admin@drelizabethhawkes.com">
            admin@drelizabethhawkes.com
          </a>
          , with the word ‘complaint’ in the subject of the email.
        </p>
        <p className="pt-4">
          You will receive a written acknowledgement within 2 working days of
          receipt of the complaint.
        </p>
        <p className="pt-4">
          You will then receive a full response within 20 working days of
          receipt of the complaint. If, for any reason, the investigation takes
          longer than this time period, you will receive an email explaining the
          reason for the delay and an expected time frame for the investigation
          to be completed. Following completion, you will receive a full
          response within 5 working days.
        </p>
        <p className="pt-4">
          If you are not satisfied with the investigation and response to your
          complaint, then please respond by email to{" "}
          <a href="mailto:admin@drelizabethhawkes.com">
            admin@drelizabethhawkes.com
          </a>
          . You can also write to us at the following address; “Complaint
          Appeal, Dr. Elizabeth Hawkes, Cadogan Clinic, 120 Sloane Street, SW1X
          9BW”.
        </p>
        <p className="pt-4">
          At this stage, your complaint will be further investigated and we will
          most likely invite you for a meeting to discuss things further. You
          can be accompanied by a colleague or friend.
        </p>
        <p className="pt-4">
          If, following the further investigation, you remain dissatisfied, we
          can arrange for an independent adjudication process.
        </p>
      </div>
    </div>
  </Layout>
)

export default TermsPage
